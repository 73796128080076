.ql-editor,
.message-content,
.ql-output,
.ck-content {
  white-space: normal !important;
  word-break: break-word !important;

  blockquote {
    font-style: normal;
  }

  ol {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
    list-style-type: revert;
  }
  pre, b {
    white-space: normal !important;
    word-break: break-word !important;
  }
  ul {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
    list-style-type: revert;
  }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h4,
  h5,
  h6 {
    font-weight: revert;
    margin: revert;
    padding: revert;
    display: block;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    line-height: 1.38;
  }
  h1 {
    font-size: 26.5px !important;
    margin-top: 26.5px !important;
    margin-bottom: 8px !important;
  }
  h2 {
    font-size: 21.3px !important;
    margin-top: 24px !important;
    margin-bottom: 8px !important;
  }
  h3 {
    font-size: 18.6px !important;
    margin-top: 12.3px !important;
    margin-bottom: 5.3px !important;
  }
  h4 {
    font-size: 16px !important;
    margin-top: 18.6px !important;
    margin-bottom: 5.3px !important;
  }
  h5 {
    font-size: 13.3px !important;
    margin-top: 13.3px !important;
    margin-bottom: 2.7px !important;
  }
  h6 {
    font-size: 10.6px !important;
    margin-top: 10.6px !important;
    margin-bottom: 2.7px !important;
  }
  table {
    border-collapse: collapse;
  }
  table td {
    border: 1px solid;
  }
  a {
    color: #06c;
    text-decoration: underline;
  }
  p {
    display: block;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0;
    margin-right: 0;
    padding: revert;
  }
  .text-small {
    font-size: 10px;
  }
  .text-large {
    font-size: 18px;
  }
  .text-huge {
    font-size: 32px;
  }
  .mention {
    white-space: nowrap;
    background-color: inherit;
    color: #5c69d1;
  }
  img {
    display: revert;
  }
}

.container {
  width: 500px;
}

.ck-editor {
  display: flex;
  flex-direction: column-reverse;

  .ck-toolbar.ck-toolbar_grouping {
    border-radius: 0 0 var(--ck-border-radius) var(--ck-border-radius) !important;
    border-width: 0 !important;
    margin-top: 3px;
    width: 100%;
  }

  .ck-content {
    border-radius: var(--ck-border-radius) var(--ck-border-radius) 0 0 !important;
    border-width: 0 !important;
    padding: 5px;

    font-size: 13px;
  }

  .ck-content.ck-editor__editable {
    padding: 0.75rem;
  }

  .ck-content.ck-editor__editable p {
    word-break: break-word;
  }
}

.ck-thread__container .ck-content * {
  color: #000000 !important;
}
