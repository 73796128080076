@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&display=swap');
@import './composer_content.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  touch-action: none;
  -webkit-text-size-adjust: none;
}

.dev-indicator {
  position: absolute;
  width: 100%;
  height: 10px;
  z-index: 10000;
  background-image: repeating-linear-gradient(
    -45deg,
    #f6d845,
    #f6d845 50px,
    black 50px,
    black 100px
  );
}

#root {
  position: relative;
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;
  // height: 100vh;
  // overflow: scroll;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.ascending::after {
  content: '  \02C4';
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: '  \02C5';
  display: inline-block;
  margin-left: 1em;
}

input,
textarea {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

input,
button,
textarea {
  outline: none;

  &:focus {
    outline: none;
  }
}

// This is a fix for square borders which show around MultiSelect component when we start
// typing into it. Tailwind css adds a class on textarea on input focus. Below is a targeted
// CSS change which overrides the CSS for the input class. Any changes to the Select component
// did not work
.select__input, .location-input input {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

:root {
  --app-height: 100%;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.button-background {
  background: linear-gradient(270deg, #5c69d1 0%, #8a97ff 215.05%);
  color: #fff;
}

.button-background:hover {
  background: linear-gradient(270deg, #5c69d1 0%, #8a97ff 102.04%);
}

.background {
  background: #0e151f;
}

.error-dark-background {
  background: rgba(223, 181, 99, 0.04);
  color: #dfb563;
  font-weight: 400;
}

.error-dark-text {
  color: #dfb563;
  font-weight: 400;
}

.w-65 {
  width: 17rem;
}

.input-background {
  background: #0e151f;
  color: #fff;
  border: 2px solid #8a97ff;
}

.input-background > * {
  background: #0e151f;
  -webkit-box-shadow: 0 0 0 10px #0e151f !important;
  box-shadow: 0 0 0 10px #0e151f inset !important;
  -webkit-text-fill-color: #fff;
  color: #fff;
  font-weight: 400;
}

.input-background:focus-within,
.input-background:hover,
.input-background:hover > *,
.input-background > *:focus {
  background: #12161e;
  outline: none;
  -webkit-box-shadow: 0 0 0 10px #12161e inset !important;
  box-shadow: 0 0 0 10px #12161e inset !important;
}

.input-background > *::placeholder {
  color: #718096;
  opacity: 1; /* Firefox */
}

.input-background:hover > *::placeholder,
.input-background > *:focus::placeholder {
  color: #718096;
  opacity: 1;
}

.max-w-xxs {
  max-width: 14rem;
}

.SingleDatePicker_picker__portal {
  z-index: 100 !important;
}

.vesting-date-picker .SingleDatePicker {
  display: block;
  width: 100%;
}

.vesting-date-picker .SingleDatePickerInput {
  display: block;
  width: 100%;
}

.vesting-date-picker .DateInput {
  display: block;
  width: 100%;
}

.bg-midnight {
  background-color: #0e151f;
}

.quill-better-table td {
  border-color: inherit !important;
}

reach-portal [data-reach-popover] {
  z-index: 101;
}

[data-reach-menu],
[data-reach-menu-popover] {
  z-index: 102;
}

.szh-menu-container {
  margin: 0 !important;
}

.codox-session-tooltip {
  right: 0;

  &:after {
    left: unset !important;
    right: 10px;
  }
}

.ck.ck-presence-list__counter {
  display: none;
}

.sb-avatar img {
  object-fit: contain;
}

.ql-snow .ql-editor {
  min-height: 300px;
}

.ql-content img {
  display: block;
  max-width: 600px;
}

#revision-viewer-container {
  display: none;
}

.ck-sidebar--narrow {
  min-height: unset !important;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-right {
  text-align: right;
}

.ql-mention-list {
  color: #000;
}

.ql-mention-list-container {
  padding: 4px;
}

.ql-mention-list-item {
  border-radius: 8px;
}

.ql-mention-list-item.selected {
  background-color: #5c69d1;
  color: #fff;
}

.light .animated-gradient {
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(
    110deg,
    rgba(255, 255, 255, 0) 8%,
    rgba(0, 0, 0, 0.1) 18%,
    rgba(255, 255, 255, 0) 33%
  );
}

.dark .animated-gradient {
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    110deg,
    rgba(0, 0, 0, 0) 8%,
    rgba(255, 255, 255, 0.1) 18%,
    rgba(0, 0, 0, 0) 33%
  );
}

.dark .animated-gradient,
.light .animated-gradient {
  animation: 2.5s shine linear infinite;
  background-size: 300% 100%;

  position: relative;
}

@media (min-width: 640px) {
  .custom-scrollbar::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    border: 4px solid var(--cabal-background-color);
  }

  .custom-scrollbar::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@media (min-width: 640px) {
  .absolute-custom-scrollbar::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
  }

  .absolute-custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    border: 4px solid var(--cabal-background-color);
  }

  .absolute-custom-scrollbar::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@keyframes shine {
  to {
    background-position-x: -300%;
  }
}

.intercom-lightweight-app,
.intercom-lightweight-app-launcher .intercom-launcher {
  z-index: 999 !important;

  @media (max-width: 640px) {
    display: none;
  }
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes modalZoom {
  from {
    opacity: 0.8;
    transform: scale(0.97);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

[aria-hidden='true'] [data-reach-dialog-overlay] {
  background: transparent;

  [data-reach-dialog-content] {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }
}
