.affinity-icon {
  background: url('../images/affinity-icon.svg') no-repeat center;
  background-size: contain;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.affinity-icon-12 {
  @extend .affinity-icon;
  width: 15px;
  height: 12px;
}